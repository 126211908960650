import React, { useContext } from "react";
import Icon from "../common/Icon";
import ModalContext from "../../context/modalContext";

const FlashCardCategorySelect = ({
  categories,
  selectedCategory,
  onSelectCategory,
}) => {
  const { modal, setModal, closeModal } = useContext(ModalContext);

  const setMenuOpen = () => {
    if (!modal.open) {
      setModal({
        open: true,
        header: "Select Category",
        content: Object.keys(categories).map((cat, idx) => (
          <div style={{ textAlign: "center" }} key={idx}>
            <button
              key={idx}
              onClick={() => {
                onSelectCategory(cat);
                closeModal();
              }}
              disabled={cat === selectedCategory}
            >
              {categories[cat].title}
            </button>
          </div>
        )),
      });
    } else {
      closeModal();
    }
  };

  return (
    <div
      onClick={() => setMenuOpen()}
      className="flash-card-nav-button-container"
      style={{ padding: 0 }}
    >
      <Icon name="menu" className="icon" />
    </div>
  );
};

export default FlashCardCategorySelect;
