import React, { useState } from "react";

import FlashCard from "./flashCard";
import FlashCardCategorySelect from "./flashCardCategorySelect";

const categories = {
  acronyms: { title: "Acronyms", cards: require("../../data/acronyms.json") },
  aerodynamics: {
    title: "Aerodynamics",
    cards: require("../../data/aerodynamics.json"),
  },
  "airport-operations": {
    title: "Airport Operations",
    cards: require("../../data/airport-operations.json"),
  },
  airspaces: { title: "Airspaces", cards: require("../../data/airspace.json") },
  "emergency-procedures": {
    title: "Emergency Procedures",
    cards: require("../../data/emergency-procedures.json"),
  },
  "far-aim": { title: "FAR/AIM", cards: require("../../data/far-aim.json") },
  r22: { title: "R22 Characteristics", cards: require("../../data/r22.json") },
  "warning-lights": {
    title: "Warning Lights",
    cards: require("../../data/warning-lights.json"),
  },
  weather: { title: "Weather", cards: require("../../data/weather.json") },
};

const FlashCardsHome = () => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [currentCard, setCurrentCard] = useState(null);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [currentCards, setCurrentCards] = useState([]);
  const [header, setHeader] = useState("");
  const [showBack, setShowBack] = useState(false);

  const handleSelectCategory = (category) => {
    let cat = categories[category];

    if (category === "all") {
      let cards = [];
      Object.keys(categories).forEach((key) => {
        cards.push(...categories[key].cards);
      });
      cat = {
        title: "All Categories",
        cards,
      };
    }

    if (cat) {
      const shuffledCards = cat.cards.sort(() => Math.random() - 0.5);
      setHeader(cat.title);
      setCurrentCards(shuffledCards);
      changeCard(shuffledCards);
      setSelectedCategory(category);
    }
  };

  const changeCard = (shuffledCards, num = 1) => {
    let newCardIndex = currentCardIndex + num;
    const newCard = shuffledCards[newCardIndex];
    setShowBack(false);
    setCurrentCard(
      newCard || shuffledCards[num === 1 ? 0 : shuffledCards.length - 1]
    );
    setCurrentCardIndex(
      newCardIndex >= shuffledCards.length
        ? 0
        : newCardIndex < 0
        ? shuffledCards.length - 1
        : newCardIndex
    );
  };

  const getRandomCard = () => {
    const catList = Object.keys(categories);
    handleSelectCategory(catList[Math.floor(Math.random() * catList.length)]);
  };

  return (
    <div style={{ height: "100%" }}>
      <h1>Part 141 Stage Check Flash Cards</h1>
      <div style={{ flexDirection: "row" }}>
        <div className="flex-row">
          <button onClick={() => handleSelectCategory("all")}>
            All Categories
          </button>
        </div>
        <div className="flex-row">
          <button onClick={getRandomCard}>Random Category</button>
        </div>
        <div
          className="flex-row clickable icon"
          style={{
            position: "absolute",
          }}
        >
          <FlashCardCategorySelect
            categories={categories}
            onSelectCategory={handleSelectCategory}
            selectedCategory={selectedCategory}
          />
        </div>
      </div>
      {!selectedCategory && (
        <div>
          <p>Please select a category</p>
        </div>
      )}
      <h2>
        <b>{header}</b>
      </h2>
      <div className="flash-card-container">
        {currentCard && (
          <FlashCard
            card={currentCard}
            showBack={showBack}
            setShowBack={setShowBack}
            category={selectedCategory}
            onPreviousCard={() => changeCard(currentCards, -1)}
            onNextCard={() => changeCard(currentCards)}
          />
        )}
      </div>
    </div>
  );
};

export default FlashCardsHome;
