import React from "react";
import { IconContext } from "react-icons";
import {
  FaBackwardStep,
  FaForwardStep,
  FaShuffle,
  FaCircleInfo,
  FaAlignJustify,
} from "react-icons/fa6";
import { MdMenuOpen, MdOutlineClose } from "react-icons/md";

const icons = {
  back: FaBackwardStep,
  close: MdOutlineClose,
  forward: FaForwardStep,
  info: FaCircleInfo,
  menu: MdMenuOpen,
  shuffle: FaShuffle,
  text: FaAlignJustify,
};

const Icon = ({ name, className, overrideStyle = {} }) => {
  const IconRender = icons[name];

  if (!IconRender) return null;

  return (
    <IconContext.Provider
      value={{ className: className || "generic-icon", overrideStyle }}
    >
      <IconRender />
    </IconContext.Provider>
  );
};

export default Icon;
