import React, { useState, useEffect } from "react";
import Icon from "../common/Icon";

const FlashCard = ({
  card,
  showBack,
  setShowBack,
  category,
  onPreviousCard,
  onNextCard,
  onShuffle,
}) => {
  const [showDetail, setShowDetail] = useState(false);

  useEffect(() => {
    setShowDetail(false);
  }, [card.flash]);

  const raiseClick = (type) => {
    if (type === "detail") setShowDetail(!showDetail);
    else if (type === "next") onNextCard();
    else if (type === "previous") onPreviousCard();
    else if (type === "shuffle") onShuffle();
    else if (type === "flip") setShowBack(!showBack);
  };

  const renderCardText = () => {
    const text = showDetail ? card.detail?.text : card.back?.text;
    if (!text || !text.length) return null;

    return (
      <div className="back-text">
        {text?.map((d, i) => {
          if (typeof d.header === "string") {
            return (
              <div key={i}>
                <b>{d.header}</b>
                {d.text.map((item, ii) => (
                  <p key={ii}>{item}</p>
                ))}
              </div>
            );
          }
          return <p key={i}>{d}</p>;
        })}
      </div>
    );
  };

  return (
    <div className={`flash-card flash-${category}`}>
      <div
        className="flash-card-nav-button-container"
        style={{ left: 0, borderBottomRightRadius: 20 }}
        onClick={() => raiseClick("previous")}
      >
        <Icon name="back" className={`flash-${category} icon`} />
      </div>
      <div
        className="flash-card-nav-button-container"
        style={{
          left: 50,
          borderBottomRightRadius: 20,
          borderBottomLeftRadius: 20,
        }}
        onClick={() => raiseClick("previous")}
      >
        <Icon name="shuffle" className={`flash-${category} icon`} />
      </div>
      <div
        className="flash-card-nav-button-container"
        style={{ right: 0, borderBottomLeftRadius: 20 }}
        id="next"
        onClick={() => raiseClick("next")}
      >
        <Icon name="forward" className={`flash-${category} icon`} />
      </div>
      {showBack && card.detail && (
        <div
          className="flash-card-nav-button-container"
          style={{
            right: 50,
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20,
          }}
          id="next"
          onClick={() => raiseClick("detail")}
        >
          <Icon
            name={showDetail ? "text" : "info"}
            className={`flash-${category} icon`}
          />
        </div>
      )}
      {showBack ? (
        <div className="flash-card-back" onClick={() => raiseClick("flip")}>
          {renderCardText()}
        </div>
      ) : (
        <div className="flash-card-flash" onClick={() => raiseClick("flip")}>
          {card.flash}
        </div>
      )}
    </div>
  );
};

export default FlashCard;
