import { useState } from "react";

import "./css/App.css";
import ModalContext from "./context/modalContext";
import FlashCardsHome from "./components/flashCards/flashCardsHome";
import Icon from "./components/common/Icon";

function App() {
  const [modal, setModal] = useState({
    open: false,
  });

  const closeModal = () => {
    setModal({ open: false });
  };

  return (
    <div className="App">
      {modal?.open && (
        <div className="modal-container">
          <div className="modal-overlay">
            <div
              className="modal-close"
              onClick={() => setModal({ open: false })}
            >
              <Icon name="close" className="icon" />
            </div>
            <div className="modal-header">{modal.header || null}</div>
            <div className="modal-content">{modal.content || null}</div>
          </div>
        </div>
      )}
      <div
        className={"App-content" + (modal.open ? " dimmed" : "")}
        onClick={() => {
          if (modal.open) setModal({ open: false });
        }}
      >
        <ModalContext.Provider value={{ modal, setModal, closeModal }}>
          <FlashCardsHome />
        </ModalContext.Provider>
      </div>
    </div>
  );
}

export default App;
